import React, { useState, useEffect } from "react";
import axios from "axios";

const Category = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get("https://ogtorgui.zyberapi.site/categories")
      .then((res) => setData(res.data.data))
      .catch((e) => console.log(e));
  }, []);

  return (
    <>
      {data.map(data => (
        <p>{data.name} - {data.id}</p>
      ))}
    </>
  );
};

export default Category;