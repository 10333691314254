import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import { useState } from "react";
import { Navigate } from "react-router-dom";
import Editor from "../Editor";
import Category from "./category";

export default function CreatePost() {
  const [title, setTitle] = useState('');
  const [category, setCategory] = useState('');
  const [summary, setSummary] = useState('');
  const [content, setContent] = useState('');
  const [files, setFiles] = useState('');
  const [password, setPassword] = useState('');
  const [redirect, setRedirect] = useState(false);

  async function createNewPost(ev) {
    ev.preventDefault();
    if (password === 'ogtorguiAdmin789$') {
      const data = new FormData();
      data.append('title', title);
      data.append('summary', summary);
      data.append('content', content);
      data.append('file', files[0]);
      data.append('category', category);
  
      const response = await fetch('https://ogtorgui.zyberapi.site/post', {
        method: 'POST',
        body: data,
        credentials: 'include',
      });
  
      if (response.ok) {
        setRedirect(true);
      }
    } else {
      alert('Password incorrect');
    }
  }

  if (redirect) {
    return <Navigate to={'/'} />;
  }

  return (
    <>
    <Category/>
    <form onSubmit={createNewPost}>
      <input
        type="text"
        placeholder="Title"
        value={title}
        onChange={(ev) => setTitle(ev.target.value)}
      />
      <input
        type="text"
        placeholder="Category"
        value={category}
        onChange={(ev) => setCategory(ev.target.value)}
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(ev) => setPassword(ev.target.value)}
      />
      <input
        type="text"
        placeholder="Summary"
        value={summary}
        onChange={(ev) => setSummary(ev.target.value)}
      />
      <input type="file" onChange={(ev) => setFiles(ev.target.files)} />
      <Editor value={content} onChange={setContent} />
      <button style={{ marginTop: "5px" }}>Create post</button>
    </form>
    </>
  );
}
